import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ContentfulRichText } from "utils/contentful/core";
import { Document } from "@contentful/rich-text-types";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Link from "next/link";
import EnlargableImage from "components/common/EnlargableImage";
import { shouldURLOpenInNewTab } from "utils/url";

// This is a component that knows how to render the content in a Contentful RichText Object
// See more info on Contentful RichText in utils/contentful.ts
// See this documentation for info on implementing more complex case and custom styling: https://www.contentful.com/developers/docs/javascript/tutorials/rendering-contentful-rich-text-with-javascript/
export function ContentfulRichTextView({ content }: { content: ContentfulRichText }) {
    return (
        <div className="flex flex-col gap-4 break-words" data-testid="contentful-body">
            {documentToReactComponents(content.json, {
                renderText: text => {
                    // Shift + Enter allows line breaks in Contenful UI. It comes from API as /n but must be rendered with <br />..
                    return text.split("\n").map((text, i) => (
                        <>
                            {i > 0 ? <br /> : null}
                            {text}
                        </>
                    ));
                },
                renderNode: {
                    [BLOCKS.UL_LIST]: (node: any, children) => (
                        <ul className="list-disc pl-8">{children}</ul>
                    ),
                    [BLOCKS.OL_LIST]: (node: any, children) => (
                        <ol className="list-decimal pl-8">{children}</ol>
                    ),
                    [BLOCKS.QUOTE]: (node: any, children) => (
                        <blockquote className="border-l-4 pl-4 border-mainHoliday text-secondaryDark">
                            {children}
                        </blockquote>
                    ),
                    [BLOCKS.HEADING_3]: (node: any, children) => (
                        <h3 className="text-staticMobileTitle font-medium text-main -mb-3">
                            {children}
                        </h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                        const asset = content?.links?.assets?.block?.find(
                            asset => asset.sys.id === node.data.target.sys.id
                        );
                        return asset?.contentType.includes("image") ? (
                            <div className="my-4 flex items-center justify-center">
                                <EnlargableImage
                                    src={asset.url}
                                    width={asset.width}
                                    height={asset.height}
                                    alt={asset.description ?? ""}
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "20rem",
                                        objectFit: "contain"
                                    }}
                                />
                            </div>
                        ) : asset?.contentType.includes("video") ? (
                            <video
                                className="max-h-80 max-w-3xl mx-auto my-4 w-full"
                                controls
                                src={asset.url}
                            />
                        ) : null;
                    },
                    [BLOCKS.TABLE]: (node: any, children) => (
                        <table className="w-full border-collapse my-4">
                            <tbody>{children}</tbody>
                        </table>
                    ),
                    [BLOCKS.TABLE_CELL]: (node: any, children) => (
                        <td className="border border-stroke p-2">{children}</td>
                    ),
                    [BLOCKS.TABLE_HEADER_CELL]: (node: any, children) => (
                        <th className="border border-stroke p-2 bg-mainHoliday">{children}</th>
                    ),
                    [INLINES.HYPERLINK]: (node: any, children) => (
                        <Link
                            href={node.data.uri}
                            rel="noopener noreferrer"
                            className="text-link hover:underline"
                            {...(shouldURLOpenInNewTab(node.data.uri) ? { target: "_blank" } : {})}
                        >
                            {children}
                        </Link>
                    ),
                    [INLINES.ENTRY_HYPERLINK]: (node: any, children) => (
                        <Link
                            href={"/help/articles/cid/" + node.data.target.sys.id}
                            className="text-link hover:underline"
                        >
                            {children}
                        </Link>
                    )
                }
            })}
        </div>
    );
}

export function richTextDocumentToPlainText(document: Document | undefined): string {
    // RichText is a tree of nodes. We need to traverse all nodes of the tree and extract any that are text.
    // See this documentation for example of what a RichText Document Object looks like: https://www.contentful.com/developers/docs/javascript/tutorials/rendering-contentful-rich-text-with-javascript/
    if (!document) return "";

    // A simple hacky solution for this is to use documentToReactComponents to traverse the tree, and leveraging the callbacks that are normally for customizing formatting to capture all text.
    const allText: string[] = [];
    documentToReactComponents(document, {
        renderText: (text: string) => {
            allText.push(text);
            return <>text</>;
        }
    });
    return allText.join(" ");
}

export function plainTextToRichTextDocument(text: string): Document {
    return {
        nodeType: "document",
        data: {},
        content: [
            {
                nodeType: "paragraph",
                data: {},
                content: [
                    {
                        nodeType: "text",
                        value: text,
                        marks: [],
                        data: {}
                    }
                ]
            }
        ]
    } as Document;
}
