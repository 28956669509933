import { useEffect } from "react";
import { ContentfulRichTextView } from "components/vendor/contentful";
import { useThemeContext } from "store";
import { ContentfulRichText } from "utils/contentful/core";
export const StaticPageContentAutoScroll = ({
    body
}: {
    body: ContentfulRichText;
}): JSX.Element => {
    const { mainContainerRef } = useThemeContext();
    useEffect(() => {
        mainContainerRef?.current?.scrollTo(0, 0);
    }, [body.json, mainContainerRef]);
    return <ContentfulRichTextView content={body} />;
};
