import { CaretRight } from "phosphor-react";
import React from "react";
import { BreadcrumbLink } from "./BreadcrumbLink";
import { twMerge } from "tailwind-merge";

export interface BreadcrumbsItem {
    label: string;
    url?: string;
}
export interface BreadcrumbsProps {
    items: BreadcrumbsItem[];
    className?: string;
}

export const Breadcrumbs = ({ items, className }: BreadcrumbsProps) => {
    const lastChildIndex = items.length - 1;
    return (
        <div className={className}>
            <ul className="flex items-center">
                {items.map(({ label, url }: BreadcrumbsItem, index: number) => {
                    const isLastItem = index === lastChildIndex;
                    return (
                        <React.Fragment key={index}>
                            <li
                                className={twMerge(
                                    "font-medium",
                                    isLastItem
                                        ? "text-main text-nowrap truncate"
                                        : "text-secondaryDark"
                                )}
                            >
                                {url ? <BreadcrumbLink href={url}>{label}</BreadcrumbLink> : label}
                            </li>
                            {!isLastItem && (
                                <div className="mx-2">
                                    <CaretRight size={16} color="#656260" />
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};
