import Link from "next/link";
import { twMerge } from "tailwind-merge";

interface Props extends React.ComponentPropsWithoutRef<typeof Link> {}

export const BreadcrumbLink = ({ className, children, ...props }: Props) => {
    return (
        <Link
            className={twMerge("text-secondaryDark hover:underline text-nowrap", className)}
            {...props}
        >
            {children}
        </Link>
    );
};
