import { DateTime } from "luxon";
import { Wrench } from "phosphor-react";
import HeaderBannerLayout from "components/common/v2/HeaderBannerLayout";
import { Breadcrumbs } from "components/common/v2/breadcrumbs/Breadcrumbs";
import { StaticPageContentAutoScroll } from "components/StaticPageContentAutoScroll";
import { ContentfulStaticPage, getStaticPageBySlug } from "utils/contentful/static-pages";
import PageNotFound from "pages/404";

export async function getContentfulServerSideProps({
    params: { contentfulStaticPageSlug }
}: {
    params: { contentfulStaticPageSlug: string };
}) {
    try {
        const staticPage = await getStaticPageBySlug(contentfulStaticPageSlug);
        return { props: { staticPage } };
    } catch (error) {
        return { props: { error } };
    }
}

const StaticPage = ({
    staticPage: { title, body, date, parentPage }
}: {
    staticPage: ContentfulStaticPage;
}) => (
    <HeaderBannerLayout
        title={title}
        breadcrumbs={
            parentPage && (
                <Breadcrumbs
                    items={[
                        {
                            label: parentPage?.title || "",
                            url: `/${parentPage?.slug || ""}`
                        },
                        {
                            label: title
                        }
                    ]}
                />
            )
        }
    >
        {date && (
            <p className="mb-4 text-small text-secondaryDark" data-testid="date-label">
                Last Updated: {DateTime.fromISO(date).toFormat("MMMM d, yyyy")}
            </p>
        )}

        <StaticPageContentAutoScroll body={body} />
    </HeaderBannerLayout>
);

export type StaticPageOr404Props = { staticPage: ContentfulStaticPage | null; error: any | null };

export const StaticPageOr404 = ({ staticPage, error }: StaticPageOr404Props) =>
    staticPage ? (
        <StaticPage staticPage={staticPage} />
    ) : error ? (
        <HeaderBannerLayout>
            <div
                className="flex flex-grow items-center justify-center flex-col text-secondaryDark"
                data-testid="error-state"
            >
                <Wrench size={100} />
                <h1 className="text-title mt-4">This page is undergoing maintenance.</h1>
                <p className="text-header">Please check back soon!</p>
            </div>
        </HeaderBannerLayout>
    ) : (
        <PageNotFound />
    );
