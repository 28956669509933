import React from "react";
import Link from "next/link";
import { ArrowLeft } from "phosphor-react";

import { URI_HOME } from "constants/urls";
import { TabLayout } from "components/common/v2/TabNav/TabLayout";

const PageNotFound = ({ errorMessage = "" }) => (
    <TabLayout title="404 error">
        <main className="flex-1 sm:bg-white flex justify-center items-center border-t border-lightGreyBorder sm:border-none">
            <div className="p-2 md:py-12 md:px-16">
                <div className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h1
                            className="text-displayLargeMobile sm:text-displayLarge font-medium text-main tracking-tight mb-4"
                            data-testid="notFoundTitle"
                        >
                            Page not found
                        </h1>
                        <p
                            className="text-secondaryDark text-medium sm:text-header"
                            data-testid="notFoundContent"
                        >
                            {errorMessage
                                ? errorMessage
                                : "Don’t panic. What do we do when we are lost?"}
                        </p>
                        <div className="mt-6 sm:mt-8">
                            <Link
                                href={URI_HOME}
                                className="inline-flex text-medium sm:text-headerFS24 text-link hover:text-blue-600"
                            >
                                <ArrowLeft
                                    aria-hidden="true"
                                    className="h-6 w-6 md:h-8 md:w-8 inline-block mr-2 md:mr-4 text-medium md:stroke-[1.5px]"
                                    data-testid="notFoundBackIcon"
                                />
                                <span data-testid="notFoundBackContent">Go back home</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </TabLayout>
);

export default PageNotFound;
