import { Document } from "@contentful/rich-text-types";
import * as Sentry from "@sentry/nextjs";
import { CONTENTFUL_BASE_URL } from "constants/urls";

// The framework for this code came from the NextJS + Contentful quick start project
// Link: https://www.contentful.com/nextjs-starter/

// -- MARK -- Contentful Proprietary Data Types
// Contentful has a few special data types that we map to TypeScript Objects, and then corresponding React components that know how to render them.
// See the components in the components/help-center/contentful.tsx

// -- Media
// Media represents an image or video file that can be uploaded to Contentful and then referenced either directly as a field (like specifying an article icon) or inside of RichText (like showing an image in the body of an article)

// Here are the fields we'll request in our GraphQL when requesting a field of type Media
export const MEDIA_FIELDS = `
  sys {
    id
  }
  description
  contentType
  url
  width
  height
`;

// ...and corresponding Object type to map into here
export type ContentfulMedia = {
    sys: {
        id: string;
    };
    description: string | null;
    contentType: string;
    url: string;
    width: number;
    height: number;
};

// -- RichText
// Contentful returns content created in it's WYSIWYG editor as a special JSON object
// The field type is RichText in their docs. Read more about it here: https://www.contentful.com/developers/docs/concepts/rich-text/
// We've built corresponding components that know how to render ContentfulRichText.
// Right now we only use RichText in the Articles (for the main body content), but in the future it could be used in other places too.

// Here are the fields we'll request in our GraphQL when requesting a field of type RichText
export const RICH_TEXT_FIELDS = `
  body {
    json
    links {
      assets {
        block {
          ${MEDIA_FIELDS}
        }
      }
    }
  }
`;

// ...and corresponding Object type to map into here
export type ContentfulRichText = {
    json: Document;
    links: {
        assets: {
            block: ContentfulMedia[];
        };
    };
};

// -- MARK -- Contentful Networking and Data Fetching

export async function fetchGraphQL(query: string): Promise<any> {
    return fetch(
        `${CONTENTFUL_BASE_URL}${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.CONTENTFUL_ENVIRONMENT}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`
            },
            body: JSON.stringify({ query })
        }
    ).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json: any) => {
                Sentry.captureException(new Error("Contentful API Error"), {
                    contexts: {
                        details: json
                    }
                });
                throw json;
            });
        }
    });
}
