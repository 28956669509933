import { ContentfulRichText, RICH_TEXT_FIELDS, fetchGraphQL } from "./core";

// -- MARK -- Contentful Content Objects

// Here are the fields we'll request in our GraphQL when requesting an ReleaseUpdate to render in full
const CONTENTFUL_STATIC_PAGE_FIELDS = `
  sys {
    id
  }
  parentPage {
    sys {
      id
    }
    ... on StaticPage {
      title
      slug
    }
  }
  title
  slug
  date
  ${RICH_TEXT_FIELDS}
`;

// ...and corresponding Object type to map into here
export type ContentfulStaticPage = {
    sys: {
        id: string;
    };
    title: string;
    slug: string;
    date?: string; // DateTime string in ISO format "YYYY-MM-DDThh:mm:ss.sssZ"
    body: ContentfulRichText;
    parentPage?: {
        id: string;
        title: string;
        slug: string;
    };
};

// -- MARK -- Networking and Data Fetching Helper Functions
// With the above types defined, we can now write functions that fetch data from Contentful and map the result to our types for our UIs to render.

export async function getStaticPageBySlug(slug: string): Promise<ContentfulStaticPage | null> {
    return fetchGraphQL(
        `query {
          staticPageCollection(where: { slug: "${slug}" }, limit: 1) {
            items {
              ${CONTENTFUL_STATIC_PAGE_FIELDS}
            }
          }
        }`
    ).then(entry => entry?.data?.staticPageCollection?.items?.[0] ?? null);
}
